import Header from "../components/Header/Header";
import FormSociety from "../components/FormSociety/FormSociety";

const SocietyAddingPage = () => {
    return (
        <>
            <Header />
            <FormSociety />
        </>

    )
}

export default SocietyAddingPage;
